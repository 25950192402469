import { INavigationDataTreeUnit } from '../../model/navigation.model';
import { EUserRole } from '../../model/user.model';

const isVisibleForRoles = (
  navUnit: INavigationDataTreeUnit,
  userRoles: EUserRole[]
) =>
  !!navUnit.visibleForUsers?.some((userType) => userRoles.includes(userType));

const isVisibleForNoRoles = (navUnit: INavigationDataTreeUnit) =>
  navUnit.visibleForUsers == null || !navUnit.visibleForUsers.length;

export const isNavUnitVisible = (
  navUnit: INavigationDataTreeUnit,
  isLoggedIn: boolean,
  userRoles?: EUserRole[]
): boolean =>
  userRoles?.length && isLoggedIn
    ? isVisibleForRoles(navUnit, userRoles)
    : isVisibleForNoRoles(navUnit);
