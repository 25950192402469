<section class="flex h-full flex-col">
    <mat-progress-bar
        class="opacity-0"
        [class.opacity-100]="loader.isLoading()"
        mode="indeterminate"></mat-progress-bar>
    <shrl-top-bar> </shrl-top-bar>
    <mat-sidenav-container class="h-full">
        <mat-sidenav
            class="mr-14"
            mode="over"
            [autoFocus]="false"
            [opened]="navigationService.sidenavOpened$ | async"
            (closedStart)="navigationService.sidenavOpened(false)">
            <shrl-sidenav></shrl-sidenav>
        </mat-sidenav>
        <mat-sidenav-content #pageContent>
            <ng-content></ng-content>
            <shrl-footer></shrl-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</section>
